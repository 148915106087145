import React from 'react';
import ParticlesBg from 'particles-bg';
// Components
import Navigation from './components/Navigation/Navigation';
import Logo from './components/Logo/Logo';
import Rank from './components/Rank';
import ImageLinkForm from './components/Forms/ImageLinkForm';
import FaceRecognition from './components/FaceRecognition';
import Signin from './components/Signin/Signin';
import Register from './components/Register/Register';
// Styles
import './App.css';

// Constants
const INITIAL_STATE = {
  input: '',
  imgUrl: '',
  boxes: [],
  route: 'signin',
  isSignedIn: false,
  user: {
    id: '',
    name: '',
    email: '',
    entries: 0,
    joined: ''
  }
};

const SERVER_URL = 'https://test.mrstoreeirl.a2hosted.com/face-recognition-ai-api';

class App extends React.Component {
  constructor() {
    super();
    this.state = INITIAL_STATE;
  }

  updateEntries = () => {
    // Update entries at the server
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: this.state.user.id
      })
    }
    fetch(`${SERVER_URL}/image`, options)
      .then(response => response.json())
      .then(data => {
        if (data.entries) {
          // Update entries in state.user
          this.setState(Object.assign(this.state.user, { entries: data.entries }));
        } else {
          alert(data.message);
          // Code to manage failure on updating
        }
      })
      .catch(console.log);
  }

  loadUser = (userData) => {
    this.setState({
      user: {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        entries: userData.entries,
        joined: userData.joined
      }
    });
  }

  calculateFaceLocation = (data) => {
    let faces = data.outputs[0].data.regions;
    const image = document.getElementById('input-image');
    const width = Number(image.width);
    const height = Number(image.height);

    let bounding_boxes = []

    faces.forEach(face => {
      bounding_boxes.push({
        x0: face.region_info.bounding_box.left_col * width,
        y0: face.region_info.bounding_box.top_row * height,
        x1: face.region_info.bounding_box.right_col * width,
        y1: face.region_info.bounding_box.bottom_row * height,
      });
    });

    return bounding_boxes;
  }

  displayFaceBox = (boxes) => {
    this.setState({ boxes: boxes });
  }
  // Event handlers
  handleInputChange = (e) => {
    this.setState({ input: e.target.value });
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    const imgUrl = this.state.input;
    this.setState({ imgUrl: imgUrl });
    //Call server for image processing
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: this.state.user.id,
        imgUrl: imgUrl
      })
    }
    fetch(`${SERVER_URL}/image`, options)
      .then(response => response.json())
      .then(data => {
        if (data.message === 'success') {
          let result = data.result;
          let boxes = this.calculateFaceLocation(result);
          this.displayFaceBox(boxes);
          this.updateEntries(); // Increase user's number of entries 
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log('error:', error));    

  }
  handleRouteChange = (route) => {
    if (route === 'signin' || route === 'register') {
      this.setState(INITIAL_STATE)
    } else if (route === 'home') {
      this.setState({ isSignedIn: true})
    }
    
    this.setState({ route: route })
  }

  render() {
    return (
      <div className='App'>
        <header>
          <Navigation onRouteChange={this.handleRouteChange} isSignedIn={this.state.isSignedIn} />
        </header>
        <main>
          { 
            this.state.route === 'home' ? 
            <div>
              <Logo />
              <Rank userName={this.state.user.name} userEntries={this.state.user.entries} />
              <br />
              <ImageLinkForm onInputChange={this.handleInputChange} onSubmit={this.handleSubmit} />
              <FaceRecognition boxes={this.state.boxes} imgUrl={this.state.imgUrl} />
            </div> :
            (
              this.state.route === 'signin' ?
              <Signin onRouteChange={this.handleRouteChange} loadUser={this.loadUser} serverUrl={SERVER_URL} /> :
              <Register onRouteChange={this.handleRouteChange} loadUser={this.loadUser} serverUrl={SERVER_URL} />
            )
          } 
        </main>
        <footer>
          {/* Footer components*/}
        </footer>
        <ParticlesBg color='#FAFAFA' type="cobweb" num={100} bg={true} />
      </div>
    );
  }
}

export default App;