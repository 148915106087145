import React from "react";
import './ImageLinkForm.css';

const ImageLinkForm = ({ onInputChange, onSubmit }) => {
    return (
        <section className="flex flex-column items-center">
            <p>
                {'This Magic Brain will detect faces in your pictures. Give it a try!'}
            </p>
            <form className="section__form br3 shadow-5 pa3 w-75" onSubmit={onSubmit}>
                <p className="center">
                    <input 
                        className="f4 pa2 w-50" 
                        placeholder="Paste img url" 
                        type="text" name="imag_url" 
                        id="img_url" 
                        onChange={onInputChange}
                    />
                    <button className="f4 w-20 ph2 pv2 white bg-light-purple" type="submit">Detect</button>
                </p>
            </form>
        </section>
    );
}

export default ImageLinkForm;