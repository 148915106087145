import React from "react";
import './FaceRecognition.css'

const FaceRecognition = ({ imgUrl, boxes }) => {

    let bounding_boxes_div = boxes.map((box, i) => {
        return (
            <div key={"face_"+i}
                className="bounding-box" 
                style={{
                    top: box.y0,
                    left: box.x0,
                    width: box.x1 - box.x0,
                    height: box.y1 - box.y0,
                }}>
            </div> 
        )
    })

    return (
        <div className="pa3 flex justify-center">
            <div className="mt2 relative">
                <img id="input-image" className="shadow-5" src={imgUrl} alt="" width='500px' />
                {bounding_boxes_div}
            </div>
        </div>
    );
}

export default FaceRecognition;