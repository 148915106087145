import React from "react";

class Register extends React.Component {
    constructor() {
        super();
        this.state = {
            registerName: '',
            registerEmail: '',
            registerPassword: ''
        }
    }

    handleEmailChange = (e) => {
        this.setState({ registerEmail: e.target.value })
    }
    
    handlePasswordChange = (e) => {
        this.setState({ registerPassword: e.target.value })
    }
    
    handleNameChange = (e) => {
        this.setState({ registerName: e.target.value })
    }

    handleSubmitRegister = () => {
        const { serverUrl } = this.props;
        // Send post request
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: this.state.registerName,
                email: this.state.registerEmail,
                password: this.state.registerPassword
            })
        }
        fetch(`${serverUrl}/register`, options)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data.message === 'success') {
                    this.props.loadUser(data.user);
                    this.props.onRouteChange('home');
                } else {
                    alert(data.message);
                    // Code to manage register failure
                }
            });
    }



    render() {
        const { onRouteChange } = this.props;
        return (
            <article className="br3 ba b--black-10 mv4 w-100 w-50-m w-34-l mw6 shadow-5 center">
                <main className="pa4 black-80">
                <div className="measure center">
                    <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                        <legend className="f2 fw6 ph0 mh0">Register</legend>
                        <div className="mt3">
                            <label className="db fw6 lh-copy f6" htmlFor="name">Name</label>
                            <input 
                                className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                                type="text" 
                                name="name"  
                                id="name"
                                onChange={this.handleNameChange}
                            />
                        </div>
                        <div className="mv3">
                            <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                            <input 
                                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                                type="email" 
                                name="email"  
                                id="email"
                                onChange={this.handleEmailChange}
                            />
                        </div>
                        <div className="mv3">
                            <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
                            <input 
                                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                                type="password" 
                                name="password"  
                                id="password"
                                onChange={this.handlePasswordChange}
                            />
                        </div>
                    </fieldset>
                    <div className="">
                        <input
                            onClick={this.handleSubmitRegister}
                            className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib"
                            type="submit" 
                            value="Register"
                        />
                    </div>
                    <div className="lh-copy mt3">
                        <p onClick={() => onRouteChange('signin')} className="f6 link dim black db pointer">Already have an account?</p>
                    </div>
                </div>
                </main>
            </article>
        );
    }
}

export default Register;