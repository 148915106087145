import React from "react";
import Tilt from 'react-parallax-tilt';
// Styles
import './Logo.css'
// Assets
import brain from './brain-logo-80.png';


const Logo = () => {
  return (
    <div className="ma4">
        <Tilt style={{width: '150px'}}>
          <div className="tilt br2 shadow-2 pa3" style={{ height: '150px' }}>
            <img className="logo" src={brain} alt="Face Recog AI logo" />
          </div>
        </Tilt>            
    </div>
  );
}

export default Logo;